import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO, Listen, EpisodeItem, TrailerItem } from "components";
import { List } from "components/layout";
import Image from "gatsby-image";
import { BsArrowRight } from "react-icons/bs";
import { kebabCase } from "helpers";
import { getGatsbyImage } from "helpers";
import useMenu from "state/useMenu";
/* Episodes Template
============================================================================= */

const EpisodesTemplate = ({ pageContext }) => {
	// Config
	const { podcast, episodes, index } = pageContext;
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render Episodes
	const renderEpisodes = () => {
		return episodes.map((episode, i) => {
			return (
				<EpisodeItem
					key={episode.name}
					episode={episode}
					episodeNumber={episodes.length - i}
				/>
			);
		});
	};

	// Render
	if (!episodes.length) return null;
	return (
		<>
			<SEO title={`${podcast.name}/${index}`} />
			<List
				title="Episodes"
				ariaLabel="Episodes"
				grid="grid-cols-1 gap-6 padding-bottom"
			>
				{renderEpisodes()}
			</List>
		</>
	);
};

/* Export
============================================================================= */

export default EpisodesTemplate;
